<template>
    <div class="download">
        <img src="@/assets/img/download.webp" alt="">
        <div class="download-bt">
            <a href='https://itunes.apple.com/cn/app/vccamp/id1539116139?mt=8'><botton>立即下载</botton></a>
            <a href="https://www.vccore.com/download/vccore_1.0-v7a-release.apk"><botton>立即下载</botton></a>
            <a href="https://www.vccore.com/download/win32/VCCORE_setup.exe"><botton>立即下载</botton></a>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.download{
    position: relative;
    margin-top: 1.5rem;
    img{
        width: 100%;
    }
    .download-bt{
        width: 100%;
        position: absolute;
        bottom: 2rem;
        display: flex;
        justify-content: space-around;
        botton{
            background-color: #42bd56;
            color: white;
            padding: .2rem .5rem;
            border-radius: .4rem;
        }
    }
}
</style>