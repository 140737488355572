import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'vant/lib/index.css';
import { Swipe, SwipeItem, Circle, Dialog, Overlay, TextEllipsis, Popup, Sidebar, SidebarItem } from 'vant';

import router from './router'
let app = createApp(App);
app.use(store);
app.use(router);
app.use(ElementPlus, { size: 'small', zIndex: 3000 });
app.use(Swipe);
app.use(SwipeItem);
app.use(Circle);
app.use(Dialog);
app.use(Overlay);
app.use(TextEllipsis);
app.use(Popup);
app.use(Sidebar);
app.use(SidebarItem);

app.mount('#app');
