<template>
    <header class="top-box">
        <router-link to="/">
            <div class="title">
                <img src="@/assets/img/log.jpg" alt="">
                <span>vccore 一键加速</span>
            </div>
        </router-link>
    </header>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
    .top-box{
        width: 100vw;
        height: 2.5rem;
        position: fixed;
        top: 0;
        left: 0;
        font-size: 1rem;
        padding: .5rem;
        font-size: 1.2rem;
        z-index: 54;
        box-shadow: 0 1px 5px #666666;
        background-color: white;
        a{
            height: 100%;
            text-decoration: none;
            display: flex;
            align-items: center;
            color: black;
            .title{
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                    width: 2rem;
                    padding: 0 .3rem;
                }
            }
        }
    }
</style>