import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/Home/home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: HomeView
      },
      {
        path: 'broadcast',// 跨境电商直播
        name: 'broadcast',
        component: () => (import(/* webpackChunkName: "group-user" */ '@/views/Solution/broadcast.vue'))
      },
      {
        path: 'chatGPT',// chatGPT
        name: 'chatGPT',
        component: () => (import(/* webpackChunkName: "group-user" */ '@/views/Solution/chatGPT.vue'))
      },
      {
        path: 'guide', // 操作指南
        name: 'guide',
        component: () => (import(/* webpackChunkName: "group-user" */ '@/views/Guide/guide.vue'))
      },
      {
        path: 'newActivity', // 最新活动
        name: 'newActivity',
        component: () => import(/* webpackChunkName: "group-user" */ '@/views/NewActivity/newActivity.vue')
      },
      {
        path: 'about', // 关于我们
        name: 'about',
        component: () => import(/* webpackChunkName: "group-user" */ '@/views/About/aboutWe.vue')
      }
    ]
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 切换页面始终滚动到顶部
    return { top: 0 }
  }
})

export default router
