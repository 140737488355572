<template>
    <div class="buttom">
        <p>©2023 - vccore 一键开启跨境电商时代版权所有</p>
        <p><a href="https://beian.miit.gov.cn">粤ICP备13071117号</a></p>
    </div>
</template>
<style lang="scss" scoped>
    .buttom{
        background-color: #f5f5f5;
        border: 1px solid #f5f5f5;
        color: #666666;
        text-align: center;
        a{color: #666666;}
    }
</style>